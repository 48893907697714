import React from 'react';
import Metadata from '../components/Metadata';
import Section from '../components/Section';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandsHelping,
  faSmileBeam,
  faListAlt,
  faFingerprint,
  faChartLine,
  faComments,
  faUserFriends,
} from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import FlowChart from '../components/FlowChart';
import ShowMoreButton from '../components/ShowMoreButton';
import Slider from '../components/Slider';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin-top: 2rem;
  }

  section {
    @media (min-width: 768px) {
      padding-block: 4rem;
    }
  }
`;

const StyledImageTeaser = styled.div`
  display: flex;
  flex-direction: column-reverse;

  h2 {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  @media (min-width: 920px) {
    p,
    div {
      font-size: 2rem;
    }
    margin-bottom: 2rem;
    display: grid;
    grid-gap: 5rem;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledImageContainer = styled.div`
  margin-bottom: 0;
  border-radius: 32px;

  @media (min-width: 920px) {
    order: ${({ reverse }) => (reverse ? '-1' : 'revert')};
  }
`;

const StyledColumnText = styled.div`
  @media (min-width: 920px) {
    display: flex;
    gap: 4rem;

    > div {
      width: 50%;
    }
  }
`;

const StyledTeaserHeading = styled.div`
  font-size: 2rem;
  margin-top: 1rem;
  @media (min-width: 920px) {
    margin-top: 0;
    font-size: 2.5rem !important;
  }
`;

const StyledSliderListItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

function AutismusTherapie() {
  return (
    <>
      <Metadata title='Autismus Therapie' />
      <StyledContainer>
        <h1>Autismustherapie</h1>
        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div style={{ marginBottom: '2rem' }}>
              <StyledTeaserHeading>
                Wir bieten autismusspezifische Therapie für unterschiedliche
                Altersgruppen an
              </StyledTeaserHeading>
            </div>
            <StyledImageContainer>
              <StaticImage
                src='../images/Autismustherapie.png'
                alt='Kinderhände'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>

          <StyledColumnText>
            <div>
              <p>
                Wir unterstützen unsere Klient:innen dabei, ihr volles Potential
                zu entfalten und für ihre Bedürfnisse und Ziele einzustehen. Sie
                sollen das Maß an Teilhabe am gesellschaftlichen Leben erreichen
                können, welches sie selbstbestimmt leben lässt.
              </p>
            </div>
            <div>
              <p>
                Unsere therapeutische und pädagogische Arbeit legt daher den
                Fokus auf individuelle Therapie in einem personenzentrierten
                Umfeld durch evidenzbasierte Methoden. Wir erkennen den
                einzigartigen Charakter, die Stärken und Schwächen sowie die
                Bedürfnisse unserer Klient:innen an und unterstützen sie dabei
                ihre Kompetenzen zu erweitern.
              </p>
            </div>
          </StyledColumnText>
          <p>
            Zu unseren Angeboten gehören auch die Beratung und Anleitung der
            Bezugspersonen und Institutionen aus dem sozialen Umfeld unserer
            Klient:innen.
          </p>
        </Section>

        <Section
          heading='Auf einen Blick'
          backgroundColor='primary'
          maxWidth='100ch'
        >
          <div style={{ marginTop: '2rem' }}>
            <Slider>
              <StyledSliderListItem>
                <FontAwesomeIcon
                  size='3x'
                  icon={faHandsHelping}
                ></FontAwesomeIcon>
                <span>
                  Wir legen Wert auf eine therapeutische Beziehung, die durch
                  Vertrauen und Wertschätzung geprägt ist.
                </span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon size='3x' icon={faSmileBeam}></FontAwesomeIcon>
                <span>Wir möchten, dass das Lernen Freude bereitet.</span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon size='3x' icon={faListAlt}></FontAwesomeIcon>
                <span>
                  Wir stufen vorhandene Fähigkeiten und Kompetenzen ein und
                  arbeiten darauf basierend individuelle Therapieziele aus.
                </span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon
                  size='3x'
                  icon={faFingerprint}
                ></FontAwesomeIcon>
                <span>
                  Wir setzen eine individuelle und ganzheitliche Förderung um.
                </span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon size='3x' icon={faChartLine}></FontAwesomeIcon>
                <span>
                  Wir evaluieren fortlaufend die Lernfortschritte und passen die
                  Strategien bedürfnisorientiert an.
                </span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon size='3x' icon={faComments}></FontAwesomeIcon>
                <span>
                  Wir stehen in regelmäßigem Austausch mit den wichtigen
                  Bezugspersonen und leiten diese gerne an.
                </span>
              </StyledSliderListItem>
              <StyledSliderListItem>
                <FontAwesomeIcon
                  size='3x'
                  icon={faUserFriends}
                ></FontAwesomeIcon>
                <span>
                  Wir freuen uns über Hospitationen von Bezugspersonen während
                  der Therapien.
                </span>
              </StyledSliderListItem>
            </Slider>
          </div>
        </Section>
        <Section heading='Ablauf' maxWidth='60ch'>
          <FlowChart></FlowChart>
        </Section>

        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div>
              <h2>Kinder (2 - 12 Jahre)</h2>
              <p>
                Wir bieten Therapieplätze für{' '}
                <strong>noch nicht eingeschulte und eingeschulte Kinder</strong>
                , bei denen eine <strong>Autismus-Diagnose </strong>
                vorliegt.
                <br />
              </p>
              <ShowMoreButton to='/kinder'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer>
              <StaticImage
                src='../images/Kinder 2-12_Teaser.png'
                alt='Bild zweier Kinder beim Spielen'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>

        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div>
              <h2>Jugendliche (12-18 Jahre)</h2>
              <p>
                Wir bieten Therapieplätze für <strong>Jugendliche</strong>, bei
                denen eine <strong>Autismus-Diagnose </strong>
                vorliegt.
                <br />
              </p>
              <ShowMoreButton to='/jugendliche'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer reverse>
              <StaticImage
                src='../images/Jugendliche.png'
                alt='Bild eines Jugendlichen'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>

        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div>
              <h2>(Junge) Erwachsene</h2>
              <p>
                Dieses Angebot richtet sich an (junge) Erwachsene mit einer
                Diagnose aus dem Autismus-Spektrum, die Unterstützung suchen und
                ihre Kompetenzen erweitern möchten.
                <br />
              </p>
              <ShowMoreButton to='/erwachsene'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer>
              <StaticImage
                src='../images/Erwachsene.png'
                alt='Abzweigende Wege'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>

        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div>
              <h2>Kostenübernahme</h2>
              <p>
                Die Kosten für die Therapie werden von den Trägern der
                Eingliederungshilfe übernommen.
                <br />
              </p>
              <ShowMoreButton to='/kostenuebernahme'>
                Mehr erfahren
              </ShowMoreButton>
            </div>
            <StyledImageContainer reverse>
              <StaticImage
                src='../images/Kostenübernahme_Teaser.png'
                alt='Kugelschreiber in der Hand'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>

        <Section maxWidth='120ch'>
          <StyledImageTeaser>
            <div>
              <h2>Methoden und Ansätze</h2>
              <p>
                Erfahren Sie mehr über unsere Methoden und Ansätze
                <br />
              </p>
              <ShowMoreButton to='/methoden'>Mehr erfahren</ShowMoreButton>
            </div>
            <StyledImageContainer>
              <StaticImage
                src='../images/MethodenAnsätze (Vorschlag).png'
                alt='Methoden und Ansätze'
              ></StaticImage>
            </StyledImageContainer>
          </StyledImageTeaser>
        </Section>
      </StyledContainer>
    </>
  );
}

export default AutismusTherapie;
