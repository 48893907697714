import React from 'react';
import styled from 'styled-components';

const StyledContactFlowChart = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 2rem;

  span {
    display: block;
  }
`;

const StyledContactFlowChartCircle = styled.div`
  background-color: var(--color-primary);
  padding: 1rem;
  border-radius: 50%;
  font-size: 21px;
  width: 16rem;
  height: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto 0;
  position: relative;

  span {
    color: white;
  }

  @media (max-width: 532px) {
    width: 12rem;
    height: 12rem;
  }

  :nth-child(4) {
    &:after {
      content: '';
    }
  }

  &:after {
    content: '\\279C';
    position: absolute;
    bottom: -34px;
    color: var(--color-primary);
    font-size: 30px;
    transform: rotate(90deg);
  }

  @media (min-width: 693px) {
    &:after {
      bottom: revert;
      right: -31px;
      transform: revert;
    }
  }

  @media (min-width: 693px) {
    &:nth-child(2) {
      &:after {
        bottom: -34px;
        right: revert;
        transform: rotate(90deg);
      }
    }

    &:nth-child(3) {
      &:after {
        left: -31px;
        right: revert;
        transform: rotate(180deg);
      }
    }

    &:nth-child(3) {
      order: 4;
    }

    &:nth-child(4) {
      order: 3;
    }
  }
`;

function FlowChart() {
  return (
    <StyledContactFlowChart>
      <StyledContactFlowChartCircle>
        <span>Kontaktaufnahme</span>
      </StyledContactFlowChartCircle>
      <StyledContactFlowChartCircle>
        <span>Anmeldegespräch</span>
      </StyledContactFlowChartCircle>
      <StyledContactFlowChartCircle>
        <span>Warteliste</span>
      </StyledContactFlowChartCircle>
      <StyledContactFlowChartCircle>
        <span>Therapiebeginn</span>
      </StyledContactFlowChartCircle>
    </StyledContactFlowChart>
  );
}

export default FlowChart;
