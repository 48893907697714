import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  background-color: var(--color-primary);
  color: white;
  border: 1px solid black;
  border-radius: 16px;
  text-decoration: none;
  display: inline-block;

  &:hover,
  &:focus {
    background-color: #1184d0;
  }
`;

function ShowMoreButton({ to, children }) {
  return <StyledLink to={to}>{children}</StyledLink>;
}

export default ShowMoreButton;
